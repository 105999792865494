<template>
  <!-- <div
    class="poster-box"
    id="poster-html"
  >
    顶部图片
    <img
      style="width:100%"
      :src="img"
      alt=""
    >
    步骤说明
    <div class="instruction">
      <div
        style="white-space:pre-wrap;font-size:18px"
        v-html="content"
      ></div>
    </div>
  </div> -->
  <div>
    <!--    <v-overlay :value="overlay" @click.native="overlayClick" opacity=0.9>-->
    <!--      <h3>点击查看活动引导</h3>-->
    <!--    </v-overlay>-->
    <!--    <div-->
    <!--        id="keyword"-->
    <!--        class="text"-->
    <!--    > {{keyword}}</div>-->
    <!--    <button-->
    <!--        style="font-size:18px"-->
    <!--        name="button"-->
    <!--        class="button"-->
    <!--        @click="copyKeyword"-->
    <!--    >点此复制关键词，扫码回复小助手</button>-->
    <!--    <div :class="{'tootip':true,'show':messageIsShow}">关键词已复制</div>-->
    <img
        v-for="(item,index) in imgList "
        :key="index"
        class="task-img"
        :src="item"
        alt=""
    >
    <div style="display:flex;flex-direction: column;align-items: center;margin-bottom: 50px">
      <button
          :style="`font-size:16px;margin: 5px 0;text-align:center;`"
          v-for="(item,index) in link_list" @click="link(item.url)"
          :key="index"
          name="button"
          class="button">
        {{ item.name }}
      </button>
    </div>
    <!--        @click="link(item.link)"-->
    <!--    >-->
    <!--    <button-->
    <!--        style="font-size:18px"-->
    <!--        name="button"-->
    <!--        class="button"-->
    <!--        @click="copyKeyword"-->
    <!--    >点此复制关键词，扫码回复小助手</button>-->
  </div>


</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {getTaskBotURL, getTaskInfo} from "../api/task";

export default {
  data() {
    return {
      messageIsShow: false,
      refer: "start",
      // img: "",
      // content: '',
      title: '',
      visitorId: '', //唯一机器码
      imgList: [],
      keyword: '',
      unionid: "",
      project_name: "",
      link_list: [],
      // overlay: true,
    }
  },
  created() {
    // window.vue = this
    this.project_name = this.$route.params.project_name
    this.unionid = this.$route.query.unionid
    console.log(this.project_name, this.unionid)
    this.getVisitorId()
  },
  methods: {
    //使用@fingerprintjs/fingerprintjs库获取唯一机器码
    getVisitorId() {
      const fpPromise = FingerprintJS.load()
      ;(async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise
        const result = await fp.get()

        // This is the visitor identifier:
        const visitorId = result.visitorId
        console.log(visitorId)
        this.visitorId = visitorId
        //获取到唯一机器码后 调用接口
        this.getUserInfo()
      })()
    },
    // // 页面遮蔽层点击事件
    // overlayClick(){
    //   this.overlay = !this.overlay;
    //   console.log(this.overlay);
    //   this.copyKeyword();
    // },

    //图片跳转
    link(url) {
      if (url) {
        location.href = url
      }
    },

    //获取用户数据信息
    async getUserInfo() {
      // 解密链接中的请求参数，参数内容放在data内
      // let reqData = this.$route.query.data
      // let reqData = this.$route.query
      // reqData = this.$resCommon.resDecrypt(unescape(reqData))
      // let project = this.$route.query.project
      // let project = reqData.project
      // let refer = this.$route.query.refer
      // let refer = reqData.refer
      // 用户可能是从推广链接进入系统
      // let source = reqData.source
      // let origin_ = window.location.origin
      // let path_ = window.document.location.pathname
      // console.log(origin_ + path_);
      // console.log(this.visitorId);
      // let args = {}
      // if (source) args = {project, refer, visitorId: this.visitorId, source}
      // else args = {project, refer, visitorId: this.visitorId,}
      // console.log(args);
      // 加密发送参数
      // const reqParams = this.$resCommon.reqEncrypt(args)
      // const res = await axios.get(`${origin_}/backend/task?project=${project}&refer=${refer}`)
      // const res = await axios.post(`${origin_}/backend/task`, {data: reqParams})
      // const res = await axios.get(`${origin_}/backend/task`, { params: args })
      // const res = await axios.get(`http://localhost:5099/task`, {params: args})
      // const res = await axios.post(`http://localhost:5000/task`, {data: reqParams})
      //const res = await axios.get(`https://dev.poster.ceba.ceshiren.com/backend/dev/task?project=${project}&refer=${refer}&referrer=${origin_ + path_}&visitorId=${this.visitorId}`)
      // console.log(res);
      // 解密返回结果
      // const resData = this.$resCommon.getResData(res)
      const params = {
        visitorId: this.visitorId,
        unionid: this.unionid
      }
      const taskInfoRes = await getTaskInfo(params, this.project_name)
      const taskInfoResData = taskInfoRes.data.data
      this.link_list = taskInfoResData.link_list
      this.title = taskInfoResData.title
      // 获取info接口的图片列表
      if (taskInfoResData.img_list) {
        this.imgList = taskInfoResData.img_list
      }
      // 获取小助手二维码图片url，并将其添加到图片列表第一位
      const taskBotURL = await getTaskBotURL(params, this.project_name)
      this.imgList.splice(0, 0, taskBotURL.data.data.url)
      // if (resData.content) {
      //   this.content = resData.content
      // }

      // if (resData.headimgurl) {
      //   this.headimgurl = resData.headimgurl
      // }
      // if (resData.nickname) {
      //   this.nickname = resData.nickname
      // }
      // if (resData.keyword) {
      //   this.keyword = resData.keyword;
      //   console.log('load', this.keyword)
      // }
    },
    // copyKeyword() {
    //   this.messageIsShow = true
    //   setTimeout(() => {
    //     this.messageIsShow = false
    //   }, 2000)
    //   let text = document.getElementById('keyword').innerText
    //   console.log('copy', text)
    //   this.$copyText(this.keyword).then(() => {
    //     console.log('复制成功')
    //   })
    // }
  },
  mounted() {
    // window.vue = this;
    console.log(this.title);
    document.title = this.title
  },
  watch: {
    title: {
      handler(newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    },
  }
}
</script>

<style scoped>
.poster-box {
  position: absolute;
  top: 0px;
}

.bg-img {
  width: 100%;
}

.instruction {
  width: 100%;

  color: #333;
  padding: 0 1rem;
  margin-top: 10px;
}

.v-application p {
  margin: 0;
  font-size: 18px;
  word-wrap: break-word;
}

.task-img {
  width: 100%;
  max-width: 500px;
  object-fit: contain;
}

.tootip {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  padding: 6px 10px;
  width: 250px;
  margin: 15px auto 5px;
  color: #ffffff;
  background-color: black;
  border-radius: 5px;
  transition: all 2s;
  opacity: 0;
  font-size: 16px;
  text-align: center;
}

.show {
  opacity: 1 !important;
}

.text {
  line-height: 1;
  padding: 3px 15px;
  word-break: break-all;
  display: none;
}

.button {
  display: block;
  margin: 10px auto;
  line-height: 1.8;
  padding: 0 8px;
  background-color: #409eff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
}
</style>